import React from "react";
import { images } from "../../constants";
import { motion } from "framer-motion";
import { IconScroll } from "../../components";
import "./Hero.css";

const logos = ["logo01", "logo02", "logo03", "logo04", "logo05", "logo06"];

const Hero = () => {
  return (
    <motion.div
      initial={{ y: -100, x: "0%", opacity: 0 }}
      animate={{ y: 0, x: "0%", opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="hero"
    >
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">
            {" "}
            Navigating the digital landscape for success{" "}
          </h1>
          <p className="py-4">
            Our digital marketing agency helps businesses grow and succeed
            online through a range of services including Web Design & Web
            Development, SEO, PPC, social media marketing, and content creation.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C%20I%20am%20interested%20in%20learning%20more%20about%20your%20business.%20Could%20you%20please%20provide%20me%20with%20a%20proposal%20outlining%20your%20services%2C%20pricing%2C%20and%20any%20additional%20information%20that%20would%20help%20me%20understand%20how%20we%20might%20collaborate%3F&type=phone_number&app_absent=0"
          >
            <button className="btn-positivus">Book a consultation</button>
          </a>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
